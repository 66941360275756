import { AxiosResponse } from 'axios'
import useApi, { UseApiOptions } from '../compositions/useApi'
import { ProductEntity } from '../models/product.entity'
import { PaginationResponse } from '../response/pagination.response'
import dataTransformer from '../transformers/data-transformer'
import productsTransformer from '../transformers/products-transformer'
export const useApiGetProduct = (opts?: UseApiOptions) =>
	useApi<{ slug: string }, ProductEntity>(
		({ slug }) => ({
			method: 'POST',
			url: '/product/whole-slug',
			data: {
				slug,
			},
		}),
		opts,
		dataTransformer
	)

export const useApiGetProductsNew = (opts?: UseApiOptions) =>
	useApi<any, ProductEntity[]>(
		() => ({
			method: 'GET',
			url: '/product/new',
		}),
		opts,
		dataTransformer,
		productsTransformer
	)
export const useApiGetProductsSale = (opts?: UseApiOptions) =>
	useApi(
		() => ({
			method: 'GET',
			url: '/product/sale',
		}),
		opts,
		(data: AxiosResponse) => dataTransformer(data).data,
		productsTransformer
	)
export const useApiGetProductsTop = (opts?: UseApiOptions) =>
	useApi(
		() => ({
			method: 'GET',
			url: '/product/top',
		}),
		opts,
		(data: AxiosResponse) => dataTransformer(data).data,
		productsTransformer
	)
export const useApiGetProductsCategorySale = (opts?: UseApiOptions) =>
	useApi<{ categoryId: number }, any>(
		({ categoryId }) => ({
			method: 'POST',
			url: '/product/sale-price',
			data: {
				categoryId,
			},
		}),
		opts,
		(data: AxiosResponse) => dataTransformer(data).data,
		productsTransformer
	)
export const useApiGetProducts = (opts?: UseApiOptions) =>
	useApi<{ ids: number[] }, ProductEntity[]>(
		({ ids }) => ({
			method: 'POST',
			url: '/product/array',
			data: {
				ids,
			},
		}),
		opts,
		dataTransformer
	)
export const useApiGetProductsRecommended = (opts?: UseApiOptions) =>
	useApi<{ id: number }, any>(
		({ id }) => ({
			method: 'POST',
			url: '/product/recommended',
			data: {
				id,
			},
		}),
		opts,
		dataTransformer
	)
export const useApiGetProductItems = (opts?: UseApiOptions) =>
	useApi<{ ids: number[] }, any>(
		({ ids }) => ({
			method: 'POST',
			url: '/order/product_item_array',
			data: {
				ids,
			},
		}),
		opts,
		dataTransformer
	)
export const useApiGetProductsCategoryPopular = (opts?: UseApiOptions) =>
	useApi<{ id: number }, ProductEntity[]>(
		({ id }) => ({
			method: 'POST',
			url: '/product/popular',
			data: {
				id,
			},
		}),
		opts,
		dataTransformer
	)
