import { set } from '@nuxtjs/composition-api'
import { Type } from 'class-transformer'
import getImageUrl from '~/utils/helpers/image-url'
import { AttributeValueEntity } from '~/utils/models/attribute-value.entity'
import { AttributeEntity } from '~/utils/models/attribute.entity'
import { CategoryEntity } from '~/utils/models/category.entity'
import { ImageEntity } from './image.entity'
import { ILocaleValue } from '~/utils/models/locale-value.entity'
import ReviewEntity from '~/utils/models/rewiews/review.entity'
import { BrandEntity } from '~/utils/models/brand.entity'

export enum AvailableStatus {
	available = 'available',
	gettingOut = 'is_getting_out',
	notAvailable = 'not_available',
}
export class ProductItem {
	id: 1
	product_id: number
	sku: string
	title: ILocaleValue
	slug: string
  // Todo: need fix string and numbers for price and sale price with functions
	price: string
	sale_price: string
	popular_label: 1 | 0
	top_sale_label: 1 | 0
	stock_status: AvailableStatus
	getting_out_value: 4
	stock: number
	created_at: string
	updated_at: string
	deleted_at: null

	@Type(() => AttributeValueEntity)
	attribute_value: AttributeValueEntity[]

	media?: any[] | null

	get defaultImage() {
		const imageName =
			this.media && this.media[0] && this.media[0].attributes?.image
		if (!imageName) return ''
		return getImageUrl(imageName)
	}

	get salePrice() {
		return parseFloat(this.sale_price)
	}

	get hasSale() {
		return !!this.salePrice && this.salePrice !== this.staticPrice
	}

	get staticPrice() {
		return parseFloat(this.price)
	}

	get currentPrice() {
		if (this.hasSale) {
			return this.salePrice
		} else {
			return this.staticPrice || 100
		}
	}

	get oldPrice() {
		if (this.hasSale) {
			return this.staticPrice
		}
		return 0
	}

	get sale() {
		if (!this.hasSale) return 0
		if (this.oldPrice && this.price) {
			return 100 - Math.round((this.currentPrice / this.oldPrice) * 100)
		}
		return 0
	}

	get images() {
		if (!this.media || !Array.isArray(this.media)) return []
		return this.media.map((media) => {
			const imageName = media?.attributes?.image
			return getImageUrl(imageName)
		})
	}
}
export class ProductEntity {
	id: number
	title: string
	sku: string
	description: string
	non_refundable: number
	review_rating: string
	review_count: number
	review: ReviewEntity[]
	seo_title: string
	seo_description: string
	seo_keyphrases: string
	created_at: string
	updated_at: string
	deleted_at: string
	slug: string
	tree?: CategoryEntity[]
	media?: any[] | null
  brand?: BrandEntity[]
  category?: CategoryEntity[]

	@Type(() => AttributeValueEntity)
	attribute_value: AttributeValueEntity[]

	pivot: {
		category_id: number
		product_id: number
	}

	@Type(() => ProductItem)
	product_item: ProductItem[]

	_activeVariationId: null | number

	get activeVariationId(): number {
		return this._activeVariationId || this.defaultItem?.id
	}

	set activeVariationId(val: number) {
		set(this, '_activeVariationId', val)
	}

	setActiveVariation(id: number) {
		this.activeVariationId = id
	}

	get canBuy() {
		return (
      this.activeItem?.stock_status === AvailableStatus.available ||
      this.activeItem?.stock_status === AvailableStatus.gettingOut
		)
	}

	get groupedVariations() {
		interface IGroupItem {
			attr: AttributeEntity
			values: Array<{
				productItem: ProductItem
				attrValue: AttributeValueEntity
			}>
		}
		const groups: IGroupItem[] = []
		this.product_item.forEach((productItem) => {
			productItem.attribute_value.forEach((attrValue, attrIndex) => {
				const attribute = attrValue.attribute
				const attrId = attribute.id
				const groupIdx = groups.findIndex(
					(group) => group.attr.id === attrId
				)
				if (groupIdx < 0) {
					const currentGroup = {
						attr: attribute,
						values: [
							{
								productItem,
								attrValue,
							},
						],
					}
					groups.push(currentGroup)
					if (attrIndex > 0) {
						currentGroup.values = currentGroup.values.filter(
							(groupValue) => {
								const groupValueVariation =
									groupValue.productItem
								const prevAttrs =
									this.activeItem.attribute_value.slice(
										0,
										attrIndex
									)
								const prevAttrsHas = prevAttrs.map(
									(prevAttr) => {
										const groupValueVariationAttributeIdx =
											groupValueVariation.attribute_value.findIndex(
												(gValVarAttr) =>
													gValVarAttr.attribute.id ===
													prevAttr.attribute.id &&
													gValVarAttr.id ===
													prevAttr.id
											)
										return (
											groupValueVariationAttributeIdx > -1
										)
									}
								)
								return !prevAttrsHas.includes(false)
							}
						)
					}
				} else {
					const currentGroup = groups[groupIdx]
					const attrValueInGroup = currentGroup.values.findIndex(
						(value) => value.attrValue.id === attrValue.id
					)
					if (attrValueInGroup < 0) {
						currentGroup.values.push({
							productItem,
							attrValue,
						})
					}
					if (attrIndex > 0) {
						// currentGroup.values = currentGroup.values.filter(
						// 	(groupValue) => {
						// 		const groupValueVariation =
						// 			groupValue.productItem
						// 		const prevAttrs =
						// 			this.activeItem?.attribute_value.slice(
						// 				0,
						// 				attrIndex
						// 			)
						// 		const prevAttrsHas = prevAttrs.map(
						// 			(prevAttr) => {
						// 				const groupValueVariationAttributeIdx =
						// 					groupValueVariation.attribute_value.findIndex(
						// 						(gValVarAttr) =>
						// 							gValVarAttr.attribute.id ===
						// 								prevAttr.attribute.id &&
						// 							gValVarAttr.id ===
						// 								prevAttr.id
						// 					)
						// 				return (
						// 					groupValueVariationAttributeIdx > -1
						// 				)
						// 			}
						// 		)
						// 		return !prevAttrsHas.includes(false)
						// 	}
						// )
					}
				}
			})
		})
		return groups
	}

	getActiveVariationId() {
		return this.activeVariationId
	}

	get images() {
		if (!this.media || !Array.isArray(this.media)) return []
		return (
			this.media?.map((media) => {
				const imageName = media?.attributes?.image
				return getImageUrl(imageName)
			}) || []
		)
	}

	get allImages() {
		const productImages = this.images
		const variationImages = this.product_item.reduce((arr, item) => {
			return [...arr, ...item.images]
		}, [] as string[])
		const allImages = [...variationImages, ...productImages]
		return allImages.filter((image, index) => {
			return allImages.findIndex((img) => img === image) === index
		})
	}

	get hasVariations() {
		return this.product_item?.length > 1
	}

	get activeItem(): ProductItem {
		if (!this.product_item) return {} as ProductItem
		return (
			this.product_item.find(
				(item) => item.id === this.activeVariationId
			) || this.product_item[0]
		)
	}

	get defaultItem() {
		return this.product_item && this.product_item[0]
	}

	get name() {
		return this.activeItem?.title
	}

  get salePrice(): number {
		return parseFloat(this.activeItem?.sale_price)
	}

	get hasSale() {
		return !!this.salePrice && this.salePrice !== this.staticPrice
	}

  get staticPrice(): number {
		return parseFloat(this.activeItem?.price)
	}

  get price(): number {
		if (this.hasSale) {
			return this.salePrice
		} else {
			return this.staticPrice || 100
		}
	}

  get oldPrice(): number {
		if (this.hasSale) {
			return this.staticPrice
		}
		return 0
	}

  get diffSale(): number {
    if (!this.hasSale) return 0
    return parseFloat(String(this.oldPrice - this.price))
  }

	get sale() {
		if (!this.hasSale) return 0
		if (this.oldPrice && this.price) {
			return 100 - Math.round((this.price / this.oldPrice) * 100)
		}
		return 0
	}

  get productBrand() {
    return this.brand ? this.brand[0] || this.brand : null
  }

  get categoriesTree() {
    return this.category || []
  }
}

export class GtagProductEntity {
	id: number
	name: string
	quantity: number
	list_position: number
	price: string
	// list_name: "Search Results"
	// category: ""
	// brand: ""
}
