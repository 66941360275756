import { defineNuxtPlugin } from '@nuxtjs/composition-api'

export default defineNuxtPlugin(({ app }, inject) => {
	const prefixes: { [key: string]: string } = {
		product: 'product',
		catalog: 'catalog',
	}
	const getUrl = (routeName: string, slug: string) => {
		const path = app.localePath({
			name: prefixes[routeName],
			params: {
				slug,
			},
		})
		return path
	}
	inject('url', getUrl)
})
