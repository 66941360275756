import 'reflect-metadata'

import { defineNuxtMiddleware } from '@nuxtjs/composition-api'

import { useApiCheckCategorySeoFiltersBySlug } from '~/utils/api/category'

// Todo: move to util helpers
export default defineNuxtMiddleware(async (context) => {
  const { route } = context
  const { exec, result, error } = useApiCheckCategorySeoFiltersBySlug()

  if (route.params.friendly_slug) {
    await exec({
      friendlySlug: route.params.friendly_slug,
      categorySlug: route.params.slug,
    })
  }

  if (error.value) {
    context.error({ statusCode: 404 })
  }
})
