import useApi, {UseApiOptions} from "../compositions/useApi";
import {UserEntity} from "~/utils/models/user.entity";
import dataTransformer from '../transformers/data-transformer'
import {PetsEntity} from "~/utils/models/pets.entity";

export const useApiGetUserInfo = (opts?: UseApiOptions) =>
	useApi<{ user_id: number, session_id: string }, UserEntity >(
		({user_id, session_id}) => ({
			method: 'POST',
			url: '/user/user_data',
			data: {
				user_id,
				session_id
			},
		}),
		opts,
		dataTransformer
	)

export interface EditUserInfo {
	user_id: number,
	session_id: string,
	first_name: string,
	last_name: string,
	phone: string,
	birth_day: string,
	gender: number,
	home_pet: number[]
}
export const useApiEditUserInfo = (opts?: UseApiOptions) =>
	useApi<any, EditUserInfo>(
		(data) => ({
			method: 'POST',
			url: '/user/edit_user_data',
			data,
		}),
		opts,
		dataTransformer
	)

export interface EditUserPassword {
	user_id: number,
	session_id: string,
	email: string,
	old_password: string,
	new_password: string,
}
export const useApiEditUserPassword = (opts?: UseApiOptions) =>
	useApi<EditUserPassword, any>(
		(data) => ({
			method: 'POST',
			url: '/user/change_password',
			data,
		}),
		opts,
		dataTransformer
	)

export const useApiRemoveUserSession = (opts?: UseApiOptions) =>
	useApi<{ session_id: string }, any>(
		({session_id}) => ({
			method: 'POST',
			url: '/session/delete-by-hash',
			data: {
				session_id
			}

		}),
		opts,
		dataTransformer
	)
