export enum ModalName {
	productImages = 'product-images',
	compareList = 'compare-list',
	login = 'login',
	signup = 'signup',
	restorePassword = 'restorePassword',
	quickView = 'quickView',
	sendReview = 'sendReview',
  loginBeforeSendReview = 'loginBeforeSendReview',
	addedToCart = 'addedToCart',
	reviewPlaceholder = 'reviewPlaceholder',
	sortModal = 'sortModal',
	consultModal = 'consultModal',
	thanksModal = 'thanksModal',
	cheaperModal = 'cheaperModal',
	availableModal = 'availableModal',
	cityModal = 'cityModal',
	infoModal = 'infoModal',
	ofertModal = 'ofertModal'
}
export const ModalComponents: { [key: string]: () => Promise<any> } = {
	[ModalName.productImages]: () => import('@/components/Modals/ProductImagesModal.vue'),
	[ModalName.compareList]: () => import('@/components/Modals/CompareListModal.vue'),
	[ModalName.login]: () => import('@/components/Modals/LoginModal.vue'),
	[ModalName.signup]: () => import('@/components/Modals/SignupModal.vue'),
	[ModalName.quickView]: () => import('@/components/Modals/QuickViewModal.vue'),
	[ModalName.restorePassword]: () => import('@/components/Modals/RestorePasswordModal.vue'),
	[ModalName.sendReview]: () => import('@/components/Modals/SendReviewModal.vue'),
  [ModalName.loginBeforeSendReview]: () => import('@/components/Modals/loginBeforeSendReview.vue'),
  [ModalName.addedToCart]: () => import('@/components/Modals/AddedToCartModal.vue'),
	[ModalName.reviewPlaceholder]: () => import('@/components/Modals/ReviewPlaceholderModal.vue'),
	[ModalName.sortModal]: () => import('@/components/Modals/SortModal.vue'),
	[ModalName.consultModal]: () => import('@/components/Modals/ConsultModal.vue'),
	[ModalName.thanksModal]: () => import('@/components/Modals/ThanksModal.vue'),
	[ModalName.cheaperModal]: () => import('@/components/Modals/CheaperModal.vue'),
	[ModalName.availableModal]: () => import('@/components/Modals/AvailableModal.vue'),
	[ModalName.cityModal]: () => import('@/components/Modals/CityModal.vue'),
	[ModalName.infoModal]: () => import('@/components/Modals/InfoModal.vue'),
	[ModalName.ofertModal]: () => import('@/components/Modals/OfertModal.vue'),
}
