import { Type } from "class-transformer"
import { AttributeEntity } from "./attribute.entity"
import { ILocaleValue } from "./locale-value.entity"

export class AttributeValueEntity {
  attribute_id: number
  color: string
  created_at: string
  deleted_at: string
  id: number
  slug: string
  translation: ILocaleValue
  updated_at: string
  value: string
  @Type(() => AttributeEntity)
  attribute: AttributeEntity
}
