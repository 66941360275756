import { getterTree, mutationTree, actionTree } from 'typed-vuex'
import { useApiGetUserInfo, useApiEditUserInfo, useApiEditUserPassword, useApiRemoveUserSession } from "~/utils/api/user";
import { UserEntity } from "~/utils/models/user.entity";
import { plainToClass } from "class-transformer";
const COOKIE_KEY = 'user-session'

export const state = () => ({
	userInfo: {},
	userSelectedPets: [],
	isAuth: false,
	isFetched: false,

})
export const getters = getterTree(state, {
	userModel(state): UserEntity{
		const userModel = plainToClass(UserEntity, state.userInfo)
		return userModel
	},
})
export const mutations = mutationTree(state, {
	setUserInfo(state, data) {
		state.userInfo = data
	},
	setSelectedPets(state, data) {
		state.userSelectedPets = data
	},
	setAuth(state, status) {
		state.isAuth = status
	},
	setFetched(state, value) {
		state.isFetched = value
	},
})
export const actions = actionTree(
	{state, getters, mutations},
	{
		async init({ commit }) {
			const userSession = this.$cookies.get(COOKIE_KEY)
			if (userSession) {
				const dateNow = new Date()
				const expired = new Date(userSession.session_end)
				if (userSession.user_id && userSession.session_id && dateNow < expired) {
					await this.app.$accessor.user.fetchUserInfo()
					commit('setAuth', true)
				} else {
					await this.app.$accessor.user.removeUserSession()
				}
			} else {
				await this.app.$accessor.user.removeUserSession()
			}
		},

		async removeUserSession({ state, commit}) {
			const userSession = this.$cookies.get(COOKIE_KEY)
			if (!userSession) return
			const { exec, result, error } = useApiRemoveUserSession()
			await exec({ session_id: userSession.session_id })
			if (error.value) {
				console.error(error.value)
				return
			}
			this.$cookies.remove(COOKIE_KEY)
			commit('setUserInfo', {})
			commit('setAuth', false)
		},

		invalidUserSession({ state, commit }) {
			this.$router.push('/')
			commit('setUserInfo', {})
			commit('setAuth', false)
		},

		async fetchUserInfo({ state, commit }) {
			const userSession = this.$cookies.get(COOKIE_KEY)

			if (!userSession) {
				console.error('userSession invalid')
				this.app.$accessor.user.invalidUserSession()
				return
			}
			// if (state.isFetched) return
			commit('setFetched', false)
			const { exec, result, error } = useApiGetUserInfo()
			await exec({
				user_id: userSession.user_id,
				session_id: userSession.session_id,
			})
			if (error.value) {
				console.error(error)
				return error.value
			}

			const response = result.value
			commit('setUserInfo', response)
			commit('setFetched', true)
			return response
			// commit('setFetched', true)

		},

		async changeUserInfo({ state, commit }, data) {
			const userSession = this.$cookies.get(COOKIE_KEY)
			if (!userSession) {
				console.error('userSession invalid')
				this.app.$accessor.user.invalidUserSession()
				return
			}
			commit('setFetched', false)
			const { exec, result, error } = useApiEditUserInfo()
			const additionalData = {
				user_id: userSession.user_id,
				session_id: userSession.session_id,
				home_pets: state.userSelectedPets,
			}
			const assignData = {...data, ...additionalData}
			await exec(assignData)
			if (error.value) {
				console.log(error.value)
				if (error.value?.response.status === 422) {
					return error.value?.response?.data
				}
				return error.value?.response?.data?.message
			}
			const response = result.value
			commit('setUserInfo', response)
			commit('setFetched', true)
			return response
		},

		async changeUserPassword({ state, getters, commit }, data) {
			const userSession = this.$cookies.get(COOKIE_KEY)
			if (!userSession) {
				console.error('userSession invalid')
				this.app.$accessor.user.invalidUserSession()
				return
			}
			commit('setFetched', false)
			const { exec, result, error } = useApiEditUserPassword()
			const additionalData = {
				user_id: userSession.user_id,
				session_id: userSession.session_id,
				email: getters.userModel.email,
			}
			const assignData = {...data, ...additionalData}
			await exec(assignData)
			if (error.value) {
				console.log(error.value)
				if (error.value?.response.status === 422) {
					return error.value?.response?.data
				}
				return error.value?.response
			}
			const response = result.value
			commit('setFetched', true)
			return response
		}
	}
)
