import { Type } from 'class-transformer'
export class MenuItemEntity {
	id: number
	name: string
	type: 'text' | 'category' | 'static-url' | 'url'
	value: any
	target: '_self' | '_blank'
	enabled: boolean
	// media: Media
	data: {
		description?: string | null
		favorite_category?: string[]
		image?: string
		url?: string
	}

	@Type(() => MenuItemEntity)
	children: MenuItemEntity[]

	getName() {
		return this.name
	}

	getLink(localePath: (params: any) => string) {
		if (this.type === 'category') {
			return localePath({
				name: 'catalog',
				params: { slug: this.value.slug },
			})
		} else if (this.type === 'static-url') {
			return this.value
		} else if (this.type === 'url') {
			return this.data?.url
		}
	}
}
