export default {
  enter: 'Увійти',
  yourCity: 'Ваше місто',
  freeDelivery: 'Безкоштовна доставка по Києву',
  headerDeliveryValue: 'від 1000грн',
  today: 'Сьогодні',
  logoText: 'Гіпермаркет товарів <br> для тварин',
  needConsult: 'Потрібна консультація?',
  catalog: 'Каталог',
  items: ['Товар', 'Товару', 'Товарів'],
  reviews: ['Відгук', 'Відгуки', 'Відгуків'],
  comments: ['Коментар', 'Коментар', 'Коментарів'],
  reviewsMany: 'Відгуки',
  categories: ['Категорія', 'Категорії', 'Категорій'],
  articles: ['Стаття', 'Статті', 'Статей'],
  days: ['День', 'Дня', 'Днів'],
  aSort: 'Сортування',
  filters: 'Фільтри',
  done: 'Готово',
  sort: 'Сортувати',
  apply: 'Застосувати',
  and: 'і',
  close: 'Закрити',
  popularCategories: 'Популярні категорії',
  searchPlaceholder: 'Тут може бути ваш запит, наприклад: <span>Корм для собак</span>',
  searchPlaceholderMob: 'Я шукаю...',
  leftReview: 'Залишити відгук',
  available: 'В наявності',
  notAvailable: 'Немає в наявності',
  youChoose: 'Ви вибрали',
  clear: 'Очистити',
  price: 'Ціна',
  brand: 'Бренд',
  brands: 'Бренди',
  all: 'Всі',
  allCategories: 'Усі категорії',
  catalogItems: 'Каталог товарів',
  toCart: 'Купити',
  itemPiece: 'шт',
  goToMain: 'Повернутися на головну',
  categoryBrands: 'Бренди',
  chooseCategory: 'Обрати категорію',
  totalBrands: 'Всього брендів',
  byLetter: 'За буквою',
  byCategory: 'За категорією',
  byList: 'Списком',
  byGrid: 'Плиткою',
  popular: 'Популярне',
  watchAll: 'Дивитись все',
  watchMore: 'Дивитися ще',
  otherArticles: 'Інші статті',
  articleComments: 'Коментарі до статті',
  quickView: 'Швидкий перегляд',
  stockItems: 'Акційний товар',
  sellingHits: 'Хіти продажів',
  productsNew: 'Новинки',
  stocksMany: 'Акції',
  goToBrands: 'Повернутися до брендів',
  ends: 'Закінчується',
  sku: 'Код товару',
  buyOneClick: 'Купити в 1 клік',
  findCheaper: 'Знайшли дешевше? Знизимо ціну!',
  deliveryToCity: 'Доставка в місто',
  free: 'Безкоштовно',
  deliveryAndPayment: 'Доставка і оплата',
  aboutProduct: 'Про товар',
  description: 'Опис',
  characteristics: 'Характеристики',
  productRating: 'Рейтинг товару',
  pros: 'Переваги',
  cons: 'Недоліки',
  showMore: 'Показати ще',
  showAll: 'Показати всі',
  similarProducts: 'Схожі товари',
  otherProducts: 'Інші товари',
  economyTogether: 'Разом економніше!',
  watchedItems: 'Переглянуті товари',
  onSum: 'На суму',
  articlesMany: 'Блог про домашніх тварин та зоотовари',
  readFull: 'Читати повністю',
  readAlso: 'Читайте також',
  shareArticle: 'Поділитися статтею',
  leftComment: 'Залишити коментар',
  articleAnotation: 'Зміст статті',
  oneClick: {
    title: 'Введіть номер телефону',
    description: 'І ми Вам зателефонуємо для оформлення замовлення',
    btn: 'Чекаю дзвінка!',
  },
  productLabels: {
    topSale: 'Хіт продажу',
    popular: 'Товар дня',
    new: 'Новинка',
  },
  economy: 'Економія',
  phone: 'Телефон',
  searchByName: 'Пошук по назві',
  hereCanBeYourRequest: 'Тут може бути ваш запит, наприклад Happy Baby',
  shockPrice: 'ШОК ЦІНА',
  articlesSearch: 'Пошук по статтях',
  otherStocks: 'Інші акції',
  stockProducts: 'Акційні товари',
  yourCurrentOrder: 'Ваше поточне замовлення',
  photo: 'Фото',
  name: 'Назва',
  sum: 'Сума',
  orderSum: 'Сума замовлення',
  totalToPay: 'Всьго до сплати',
  sale: 'Знижка',
  continueShopping: 'Продовжити покупки',
  makeOrderOn: 'Оформити замовлення на',
  personalData: 'Особисті дані',
  personalDataDescription: 'Зареєстровані на нашому сайті?',
  email: 'E-mail',
  personName: "Ім'я",
  surname: 'Прізвище',
  orderComment:
    'Тут ви можете вказати особливості замовлення, побажання та інше...',
  iReceiver: 'Я одержувач замовлення',
  deliveryWayAndAdress: 'Спосіб і адреса доставки',
  deliveryWay: 'Спосіб доставки',
  wishDeliveryDate: 'Бажана дата та час доставки',
  checkoutSelfDeliveryTooltip:
    'Дати отримання товару для кожного магазину в обраному місті вказані в блоці "З якого магазину забрати"',
  deliveryAddress: 'Адреса доставки',
  deliveryStreet: 'Вулиця',
  deliveryHouse: 'Дім',
  deliveryApartment: 'Квартира',
  deliveryApartmentShort: 'кв.',
  deliveryFloor: 'Поверх',
  deliveryFloorShort: 'пов.',
  deliveryBlock: "Під'їзд",
  deliveryBlockShort: 'під.',
  deliveryAddressKyiv: 'Безкоштовна доставка по Києву при замовленні від 1000 грн',
  paymentWay: 'Спосіб оплати',
  recallToApprove: 'Передзвоніть мені для уточнення деталей замовлення',
  deliveryCost: 'Вартість доставки',
  agreeWithContact: 'Я згоден з договором',
  publicOfferta: 'Публічної оферти',
  setupMain: 'Встановити основним',
  main: 'Основний',
  itemsAdded: 'Додано товарів',
  clearList: 'Очистити список',
  show: 'Показувати',
  otherLists: 'Інші списки',
  compareList: 'Список порівняння',
  exit: 'Вийти',
  cancel: 'Скасувати',
  approve: 'Підтвердити',
  yourGender: 'Ваша стать',
  gender: {
    male: 'Чоловіча',
    female: 'Жіноча',
  },
  dateOfBirth: 'Дата народження',
  homeAnimals: 'Домашні тварини',
  animals: {
    dog: 'Собака',
    cat: 'Кіт',
    fish: 'Рибки',
    bird: 'Птах',
    reptile: 'Рептилія',
    rodent: 'Гризун',
    hourse: 'Кінь',
  },
  changePassword: 'Змінити пароль',
  oldPassword: 'Старий пароль',
  newPassword: 'Новий пароль',
  passwordConfirm: 'Підтвердження пароля',
  deleteAccount: 'Видалити акаунт',
  deleteAccountText:
    'Я усвідомлено видаляю свій аккаунт, списки бажань, історію переглядів і замовлень. Відмовляюся від можливості отримувати листи про знижки та відповіді на мої коментарі.',
  deleteCause: 'Опишіть, будь ласка, причину видалення облікового запису',
  oneMore: 'Ще один',
  onBonusBalance: 'На бонусному рахунку',
  availableToUse: 'Доступних для використання',
  willBeAvailableThrough: 'Cтанут доступні через',
  myOrders: 'Мої замовлення',
  orderStatus: {
    completed: 'Виконаний',
    pending: 'Готується до відправки',
    canceled: 'Скасований',
  },
  from: 'від',
  infoAboutOrder: 'Інформація про замовлення',
  cnt: 'Кількість',
  yourOrder: 'Ваше замовлення',
  noOrders: {
    title: 'У вас ще немає замовлень',
    description: 'Саме час це змінити!',
  },
  noWatched: {
    title: 'У вас ще немає переглянутих товарів',
    description: 'Саме час це змінити!',
  },
  noWishlist: {
    title: 'У вас ще немає товарів у списку бажань',
    description: 'Саме час це змінити!',
  },
  payment: 'Оплата',
  status: 'Статус',
  total: 'Всього',
  wishList: 'Список бажань',
  totalProducts: 'Всього товарів',
  chooseAll: 'Вибрати все',
  removeSelect: 'Зняти виділення',
  deleteChosen: 'Видалити вибране',
  watchedProducts: 'Переглянуті товари',
  mailings: 'Розсилки',
  mailingsTypes: 'Види розсилок',
  messagesSendByChannels:
    'Повідомлення відправляються за наступними каналами',
  toActiveBonusApprovePhone:
    'Для активації бонусного рахунку підтвердіть актуальний номер телефону',
  smsWasSentOnGivenNumber:
    'На вказаний номер буде вислано SMS з кодом підтвердження',
  changePhoneNumber: 'Змінити номер телефону',
  sendSms: 'Відправити SMS',
  savePhone: 'Зберегти телефон',
  onNumber: 'На номер',
  wasSentSmsWithCode: 'було вислано SMS з кодом',
  enterSmsCode: 'Введіть код з SMS',
  codeFromSms: 'Код з SMS',
  bonusBill: 'Бонусний рахунок',
  spendBonuses: 'Витратити бонуси',
  getFirstBonuses: 'Отримати перші бонуси',
  guest: 'Гість',
  dontHaveAccount: 'Немає облікового запису?',
  createItRightNow: 'Створіть його зараз',
  login: {
    title: 'Ласкаво просимо',
    description: 'Будь ласка, введіть для входу ваш Е-mail і пароль',
    loginPlaceholder: 'E-mail або телефон',
  },
  password: 'Пароль',
  forgotPassword: 'Забули пароль',
  enterUsing: 'Увійти за допомогою',
  signup: {
    title: 'Реєстрація',
    description: 'Будь ласка, введіть деяку інформацію',
  },
  restorePassword: {
    title: 'Відновлення паролю',
    description: 'Будь ласка, введіть ваш Е-mail',
    btn: 'Відновити пароль',
  },
  register: 'Зареєструватися',
  IAgreeWithPolicy: ['Я згоден з', 'Політикою конфіденційності'],
  viewAllInformation: 'Переглянути всю інформацію',
  footer: {
    copyright: 'Copyright © Zoo.com.ua, {date} | Всі права захищені',
    questions: 'З усіх питань',
  },
  createReview: {
    title: 'Створення відгуку',
    subTitle: 'Будь ласка, введіть деякі дані для створення відгуку',
    infoAboutEmail: 'Повідомити мені про відповіді через e-mail',
    productComment: 'Ваш коментар про товар',
    addPhotos: 'Додати фотографії',
    addPhotosDescription:
      'Перетягніть файли сюди, або натисніть на кнопку. Додавайте ** до 10 зображень ** у форматах: **. jpg / .gif / .png ** розміром файлу ** до 5 мб **',
  },
  reviewsText: ['Поганий', 'Так собі', 'Нормальний', 'Хороший', 'Прекрасний'],
  advantages: 'Переваги',
  disadvantages: 'Недоліки',
  chooseFiles: 'Вибрати файли',
  blog: 'Блог',
  blogSort: {
    newAsc: 'Спочатку нові',
    newDesc: 'Спочатку старі',
    quantityAsc: 'Найбільше переглядів',
    quantityDesc: 'Найменше переглядів',
  },
  brandsSclon: ['Бренд', 'Бренда', 'Брендів'],
  cart: 'Кошик',
  deliveryDepartment: 'Відділення',
  deliveryDepartmentOther: 'Бажаний спосіб та місце доставки',
  deliveryDepartmentOtherShort: 'Спосіб та місце доставки',
  errors: {
    required: 'Заповніть це поле',
    phone: 'Неправильний формат телефону',
    email: 'Неправильний формат email',
    minLength: 'Мінімальна кількість символів',
    maxLength: 'Максимальна кількість символів',
    mismatchPasswords: 'Паролі не співпадають',
    matchPasswords: 'Новый пароль повинен відрізнятись від старого',
  },
  emailAlreadyTaken: 'Користувач з таким email уже зареєстрований',
  lettersFirstName: "Ім'я має складатись лише з літер",
  lettersLastName: 'Прізвищє має складатись лише з літер',
  invalidPassword: 'Невірний пароль',
  undefinedEmail: 'Не знайдено обліковий запис за вказаним email',
  invalidOldPassword: 'Невірно введений старий пароль',
  sessionExpired: 'Час сессії сплинув',
  sortItems: {
    default: 'За замовчуванням',
    'products-date-asc': 'Спочатку старі',
    'products-date-desc': 'Спочатку нові',
    'products-price-asc': 'За зростанням ціни',
    'products-price-desc': 'За зменшенням ціни',
  },
  page404: {
    name: 'Сторінку не знайдено',
    title: 'Упс, ми тут трохи загралися, і не створили цю сторінку :(',
    subtitle:
      'Або її і не повинно було існувати, будь ласка спробуйте пошукати ще раз',
    btn: 'Перейти на головну',
  },
  addedToCart: 'доданий в кошик',
  makeOrder: 'Оформити замовлення',
  resultOrder: {
    success: {
      name: 'Дякую за замовлення',
      title: 'Ваше замовлення прийнято',
      subtitle: 'Ваше замовлення прийняте в роботу. В найближчий час менеджер Вам зателефонує.',
      btn: 'Перейти на головну',
    },
    checking: {
      name: 'Будь ласка, зачекайте',
      title: 'Триває перевірка платежу',
      subtitle: 'Ваше замовлення в обробці, це триває до декількох хвилин',
      btn: 'Перейти на головну',
    },
    failure: {
      name: 'Сталася помилка',
      title: 'Помилка! Ваше замовлення не прийнято',
      subtitle: 'Ваше замовлення не прийняте. Ви можете спробувати ще раз або ж зателефонувати для замовлення',
      btn: 'Перейти на головну',
    }
  },

  yourOrderNumber: 'Ваш номер замовлення',
  checkoutEmpty: {
    title: 'Ваш кошик порожній ',
    description:
      'На жаль, Ваш кошик порожній. Перейдіть на головну сторінку для оформлення замовлення.',
    btn: 'Перейти на головну',
  },
  deliveryInFastTime: 'Найближчим часом',
  inWorkTime: 'В робочий час',
  tag: 'Тег',
  monthNames: [
    'Січень',
    'Лютий',
    'Березень',
    'Квітень',
    'Травень',
    'Червень',
    'Липень',
    'Серпень',
    'Вересень',
    'Жовтень',
    'Листопад',
    'Грудень',
  ],
  noReviewsPlaceholder: 'Додавання нових відгуків ще недоступно',
  loginBeforeSendReview: 'Щоб залишити відгук авторизуйтесь будь ласка',
  language: 'Мова',

  cheaperModal: {
    title: 'Знайшли дешевше? <span>Отримайте знижку!</span>',
    subtitle:
      'Надішліть посилання на товар, і ми надамо вам знижку (ПОТРІБЕН ТЕКСТ)',
  },
  ok: 'Ок',

  productUrl: 'Посилання на товар',
  consultModal: {
    title: 'Потрібна консультація?',
    subtitle:
      'Заповніть коротку форму, і наш менеджер відповість вам найближчим часом',
  },
  consultThanksModal: {
    title: 'Дякуємо за заявку!',
    description: '',
  },
  cheaperThanksModal: {
    title: 'Дякуємо за заявку!',
    description: '',
  },
  oneClickThanks: {
    title: 'Дякуємо за заявку!',
    description: '',
  },
  availableThanksModal: {
    title: 'Дякуємо за заявку!',
    description: '',
  },
  reviewThanksModal: {
    title: 'Дякуємо за відгук!',
    description:
      'Ваш відгук надіслано на модерацію, якщо в ньому немає порушень правил публікації, його буде опубліковано найближчим часом.',
  },

  yourComment: 'Ваш відгук',
  sendRequest: 'Відправити заявку',
  linkToProduct: 'Посилання на товар',
  callWhenAvailable: 'Під замовлення',
  availableModal: {
    title: 'Повідомити про постачання',
    subtitle: '',
  },
  cityModal: {
    title: 'Вибір міста',
    description:
      'При виборі м.Київ вам надається <br> <span>безкоштовна доставка</span> при замовленні від 1000грн.',
  },
  cities: {
    kiev: 'Київ',
    other: 'Інше місто',
  },
  city: 'Місто',
  linkCopied: 'Посилання скопійована в буфер обміну',
  cabinetMenu: {
    personalData: 'Особисті дані',
    orders: 'Мої замовлення',
    reviews: 'Мої відгуки',
    wishlist: 'Список бажань',
    watched: 'Переглянуті товари',
    mailings: 'Розсилки',
    bonus: 'Бонусний рахунок',
  },
  search: 'Пошук',
  incognita: 'Інкогніто',
  watched: 'Переглянуті',
  noDescription: 'Немає опису',
  contacts: 'Контакти',
  information: 'Інформація',
  listSearch: 'Пошук за списком',
  successDataEdit: 'Данні оновлені',
  errorDataEdit: 'Помилка редагування',
  welcome: 'Ласкаво просимо',
  successSignUp: 'Реєстрація вдала, будь ласка авторизуйтесь',
  successRestorePassword: 'Ми надіслали новий пароль на ваш e-mail, будь ласка перевірте пошту',
  error: 'Помилка',
  serverError: 'Помилка сервера',
  kyiv: 'Київ',
  nothingNoMatch: 'Нічого не знайдено',
  titleH1: 'Інтернет-магазин товарів для тварин',
  labelPrice: 'Ціна:',
  headerMainPage: 'Інтернет-магазин товарів для тварин',
  faq: {
    contacts: 'контакти',
    title: 'Поширені запитання (FAQ)',
    question1: 'Який товар користується найбільшою популярністю?',
    answer1:
      'Найпопулярнішим товаром у розділі {currentCategory} на даний момент є (назва з посиланням на товар ТОП-1).',
    question2: 'ТОП-5 товарів {currentCategory} за версією покупців ZOO.com.ua.',
    answer2:
      'ТОП-5 товарів у розділі {currentCategory} серед покупців є: (назва з посиланням на товар ТОП-1), (назва з посиланням на товар ТОП-2), (назва з посиланням на товар ТОП-3), (назва з посиланням на товар ТОП-4), (назва з посиланням на товар ТОП-5).',
    question3: 'Яка ціна мінімальна на товари {currentCategory}?',
    answer3: 'Вартість товарів з розділу {currentCategory} від (мінімальна ціна розділу товару).',
    question4: 'Як замовити товари з розділу {currentCategory}?',
    answer4:
      'Замовити товар ви можете за телефоном або скориставшись кошиком. Також якщо у вас є які-небудь питання або пропозиції, зателефонуйте нам за вказаними номерами телефонів або напишіть нам на e-mail або viber на сторінці ',
  },
  page: 'Сторінка',
}
