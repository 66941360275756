








import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
	props: {
		error: null,
	},
	computed: {
		is404(): boolean {
			return this.error.statusCode === 404
		},
	},
})
