import useApi, {UseApiOptions} from "~/utils/compositions/useApi";
import dataTransformer from "~/utils/transformers/data-transformer";
import {Type} from "class-transformer";
import {CartEntity} from "~/utils/models/cart.entity";

export const useApiAddCart = (opts?: UseApiOptions) =>
	useApi<CartEntity, any>(
		(data) => ({
			method: 'POST',
			url: '/user/add_cart',
			data
		}),
		opts,
		dataTransformer
	)

export const useApiGetCart = (opts?: UseApiOptions) =>
	useApi<{ user_id: number, session_id: string }, any>(
		({user_id, session_id}) => ({
			method: 'POST',
			url: '/user/get_cart',
			data: {
				user_id,
				session_id,
			},
		}),
		opts,
		dataTransformer
	)

export const useApiDeleteCart = (opts?: UseApiOptions) =>
	useApi<{ user_id: number, session_id: string, product_item_id: number[] }, any>(
		({user_id, session_id, product_item_id}) => ({
			method: 'POST',
			url: '/user/delete_cart',
			data: {
				user_id,
				session_id,
				product_item_id,
			},
		}),
		opts,
		dataTransformer
	)

export const useApiIncrCart = (opts?: UseApiOptions) =>
	useApi<{ user_id: number, session_id: string, product_item_id: number }, any>(
		({user_id, session_id, product_item_id}) => ({
			method: 'POST',
			url: '/user/incr_cart',
			data: {
				user_id,
				session_id,
				product_item_id,
			},
		}),
		opts,
		dataTransformer
	)

export const useApiDecrCart = (opts?: UseApiOptions) =>
	useApi<{ user_id: number, session_id: string, product_item_id: number }, any>(
		({user_id, session_id, product_item_id}) => ({
			method: 'POST',
			url: '/user/incr_cart',
			data: {
				user_id,
				session_id,
				product_item_id,
			},
		}),
		opts,
		dataTransformer
	)
