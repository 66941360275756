import config from '../config/config'
import { AvailableStatus } from '../models/product.entity'

interface IFilters {
	attributes: { [key: string]: string[] }
	page: number
	sort?: string
}

export default class Filters {
	// constructor() {}

	// parse url {name}=value,value2;
	// returns {page: 1, attributes: {'a': ['b', 'c']}, sort: '' }
	parseUrl(url: string, isFriendlyUrl: boolean = false): IFilters {
		const filters = url
		const filtersObj: { [key: string]: string[] } = {}
		const otherObj = {
			page: 1,
			sort: 'default',
		}
		if (filters) {
			const filtersArr = filters.split(';')
			filtersArr.forEach((filterItem) => {
				const [name, vals] = filterItem.split('=')
				const values = vals.replace('/', '')
				if (name === 'page') {
					otherObj[name] = parseInt(values)
        } else if (name === 'sort') {
					otherObj[name] = values
				} else if (!isFriendlyUrl) {
          filtersObj[name] = values.split(',')
        }
      })
		}

		const data = {
			attributes: {
				...filtersObj,
			},
			...otherObj,
		}

		return data
	}

	// serialize filters to url
	// return url
  toUrl(filters: IFilters, isFriendlyUrl: boolean = false): string {
		let url = ''
		Object.keys(filters).forEach((key) => {
			const state = filters[key as keyof IFilters] as any

			if (key === 'attributes') {
				Object.keys(state).forEach((key) => {
					const values = state[key]
					if (!values.length) return
          if (isFriendlyUrl && key !== 'page' && key !== 'sort') return
					url += `${key}=${values.join(',')};`
				})
			} else if (Array.isArray(state)) {
				if (!state.length) return
        if (isFriendlyUrl && key !== 'page' && key !== 'sort') return
        url += `${key}=${state.join(',')};`
			} else {
				if (!state) return
        if (isFriendlyUrl && key !== 'page' && key !== 'sort') return
        if (key === 'page' && state === 1) return
				if (key === 'sort' && state === 'default') return
				url += `${key}=${state};`
			}
		})

		url = url.slice(0, url.length - 1)

		return url
	}

	toFacets(filters: IFilters) {
		const attributes = filters.attributes
		let facets = Object.keys(attributes).map((key) => {
			const itemValues = attributes[key]
			let attrKey = key
			if (attrKey === 'available') return false
			if (attrKey === 'price') {
				attrKey = 'product_item.minimal_price'
				return false
			} else if (attrKey === 'brands') {
				attrKey = 'brand.slug'
			} else {
				attrKey = `filters.${attrKey}.values.slug`
			}
			if (itemValues.length === 1) {
				return [`${attrKey}:${itemValues[0]}`]
			}
			return itemValues.map((value: string) => `${attrKey}:${value}`)
		})
		facets = facets.filter((item) => !!item)
		return facets as string[][]
	}

	getSortItems() {
		return [
			{
				index: 'products',
				value: 'default',
			},
			{
				index: 'products_created_at_asc',
				value: 'products-date-asc',
			},
			{
				index: 'products_created_at_desc',
				value: 'products-date-desc',
			},
			{
				index: 'products_price_asc',
				value: 'products-price-asc',
			},
			{
				index: 'products_price_desc',
				value: 'products-price-desc',
			},
		]
	}

	getIndex(sort?: string) {
		const sortItems = this.getSortItems()
		const sortItem = sortItems.find((item) => item.value === sort)
		return sortItem ? sortItem.index : sortItems[0].index
	}

	getQuery(
		filters: IFilters,
		additionalFacetFilters: string[],
		queryStr = ''
	) {
		const price = filters.attributes.price
		const facetsFilters = this.toFacets(filters)
		const availableFilter = this.getAvailableFilter(
			filters.attributes.available
		)

		const query = {
			indexName: this.getIndex(filters.sort),
			params: {
				query: queryStr,
				facets: ['*'],
				filters:
					price && price.length
						? `product_item.minimal_price:${price[0]} TO ${price[1]}`
						: '',
				facetFilters: [
					...additionalFacetFilters,
					...facetsFilters,
					availableFilter,
				],
				hitsPerPage: Number(process.env.CATALOG_PRODUCTS_PER_PAGE),
				page: filters.page - 1,
			},
		}
		return query
	}

	getAvailableFilter(available: string[]) {
		const isAvailable = available && available[0] === 'true'
		if (isAvailable) {
			return [
				`product_item.stock_status:${AvailableStatus.available}`,
				`product_item.stock_status:${AvailableStatus.gettingOut}`,
			]
		}
		return []
	}

	toQueries(
		filters: IFilters,
		additionalFacetFilters: string[],
		queryStr = ''
	) {
		const price = filters.attributes.price
		const initial = this.getQuery(filters, additionalFacetFilters, queryStr)
		const allFacets = this.toFacets(filters)
		const availableFilter = this.getAvailableFilter(
			filters.attributes.available
		)
		const restQueries = allFacets.map((facet) => {
			const facetsFilters = allFacets.filter((item) => item !== facet)
			const [facetName] = facet[0].split(':')
			const query = {
				indexName: this.getIndex(filters.sort),
				params: {
					query: queryStr,
					facets: [facetName],
					filters:
						price && price.length
							? `product_item.minimal_price:${price[0]} TO ${price[1]}`
							: '',
					facetFilters: [
						...additionalFacetFilters,
						...facetsFilters,
						availableFilter,
					],
					page: filters.page - 1,
				},
			}
			return query
		})

		const queries = [initial, ...restQueries]
		if (price && price.length) {
			const priceQuery = {
				indexName: this.getIndex(filters.sort),
				params: {
					facets: 'product_item.minimal_price',
					facetFilters: [
						...additionalFacetFilters,
						...allFacets,
						availableFilter,
					],
					page: filters.page - 1,
				},
			}
			queries.push(priceQuery as any)
		}
		return queries
	}

	getFacetValues(queries: any[]) {
		const facetQueries = queries.slice(1)
		const mainFacets = queries[0] && queries[0].facets
		facetQueries.forEach((query) => {
			Object.keys(query.facets).forEach((key) => {
				mainFacets[key] = query.facets[key]
			})
		})
		return mainFacets
	}

	getFacetStats(queries: any[]) {
		const facetQueries = queries.slice(1)
		const mainFacets = (queries[0] && queries[0].facets_stats) || {}
		facetQueries.forEach((query) => {
			if (!query.facets_stats) return
			Object.keys(query.facets_stats).forEach((key) => {
				mainFacets[key] = query.facets_stats[key]
			})
		})
		return mainFacets
	}
}
