import { defineNuxtPlugin } from '@nuxtjs/composition-api'
import Vue from 'vue'
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format'

export default defineNuxtPlugin(({ i18n }, inject) => {
	Vue.use(VueFilterDateFormat, {
		monthNames: i18n.t('monthNames'),
	})
	inject('dateFilterConfig', () => ({
		monthNames: i18n.t('monthNames'),
	}))
})
