import { getterTree, mutationTree, actionTree } from 'typed-vuex'
import { useApiGetProducts } from '~/utils/api/product'
import config from '~/utils/config/config'
const COOKIE_KEY = 'person-city'
interface ICity {
	id: number
	name: string
}
export const state = () => ({
	items: [] as ICity[],
	activeId: 1,
})
export const getters = getterTree(state, {
	cities() {
		return (i18n: any) => {
			return [
				{
					id: 1,
					name: i18n.t('cities.kiev'),
				},
				{
					id: 2,
					name: i18n.t('cities.other'),
				},
			]
		}
	},
	activeCity(state, getters) {
		return (items: ICity[]) => {
			return items.find((item) => item.id === state.activeId)
		}
	},
})

export const mutations = mutationTree(state, {
	setItems(state, items) {
		state.items = items
	},
	setActiveId(state, id) {
		state.activeId = id
	},
})

export const actions = actionTree(
	{ state, getters, mutations },
	{
		setActive({ commit }, cityId) {
			commit('setActiveId', cityId)
			this.$cookies.set(COOKIE_KEY, cityId)
		},
		init({ commit }) {
			const activeId = this.$cookies.get(COOKIE_KEY)
			commit('setActiveId', activeId ? parseInt(activeId) : 1)
		},
	}
)
