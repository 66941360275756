import useApi, {UseApiOptions} from "~/utils/compositions/useApi";
import dataTransformer from "~/utils/transformers/data-transformer";

export const useApiAddWatched = (opts?: UseApiOptions) =>
	useApi<{ user_id: number, session_id: string, product_item_id: number[] }, any []>(
		({user_id, session_id, product_item_id}) => ({
			method: 'POST',
			url: '/user/add_viewing',
			data: {
				user_id,
				session_id,
				product_item_id,
			},
		}),
		opts,
		dataTransformer
	)

export const useApiGetWatched = (opts?: UseApiOptions) =>
	useApi<{ user_id: number, session_id: string }, any>(
		({user_id, session_id}) => ({
			method: 'POST',
			url: '/user/get_viewing',
			data: {
				user_id,
				session_id,
			},
		}),
		opts,
		dataTransformer
	)

export const useApiClearWatched = (opts?: UseApiOptions) =>
	useApi<{ user_id: number, session_id: string}, any []>(
		({user_id, session_id}) => ({
			method: 'POST',
			url: '/user/clear_viewing',
			data: {
				user_id,
				session_id,
			},
		}),
		opts,
		dataTransformer
	)
