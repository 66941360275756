// eslint-disable-next-line import/no-extraneous-dependencies
import { Context } from '@nuxt/types'

import { useApiCheckCategorySeoFiltersByMultiple } from '~/utils/api/category'
import Filters from '~/utils/helpers/filters'

export default async function (context: Context) {
  const { route, redirect, app } = context
  const { path } = route

  // eslint-disable-next-line no-prototype-builtins
  if (!route.params.hasOwnProperty('pathMatch')) {
    return
  }

  let skip = false

  const slugCategory = route.params.slug
  let brandSlugs: Array<string> = []
  const attributeSlugs: Array<string> = []
  let attributeValueSlugs: Array<string> = []

  const filtersModel = new Filters()
  const filtersObj = filtersModel.parseUrl(route.params.pathMatch)

  Object.keys(filtersObj.attributes).forEach((key) => {
    if (key === 'brands') {
      const brandLength = filtersObj.attributes[key].length
      brandSlugs = filtersObj.attributes[key]

      // Can check more 2 brands -> skip
      if (brandLength > 2) {
        skip = true
      }
    } else if (key !== 'sort' && key !== 'page') {
      attributeSlugs.push(key)
      attributeValueSlugs = filtersObj.attributes[key]
    }
  })

  // Can check more 2 attributeSlug key -> skip (Not Value)
  // slugs can be 0
  skip = !skip && attributeSlugs.length > 1 ? true : skip

  if (!skip) {
    const { exec, result, error } = useApiCheckCategorySeoFiltersByMultiple()

    await exec({
      categorySlug: slugCategory,
      brandSlug: brandSlugs.length > 0 ? brandSlugs[0] : null,
      attributeSlug: attributeSlugs.length > 0 ? attributeSlugs[0] : null,
      attributeSlugValues: attributeSlugs.length > 0 ? attributeValueSlugs : null,
    })

    // Todo: error
    const resultSlug = result.value?.slug
    // console.log('resultSlug', result.value?.slug)
    if (resultSlug !== null && resultSlug !== undefined) {
      // Todo: move to helper utils buildUrl
      const filtersPart = filtersModel.toUrl(filtersObj, true)
      let filtersUrl
      const url = `/catalog/${slugCategory}/${resultSlug}`
      if (filtersPart) {
        // redirect with filters (page && sort)
        redirect(301, app.localePath(`${url}/f/${filtersPart}`))
      } else {
        redirect(301, app.localePath(url))
      }
    }
  }
}
