import { defineNuxtMiddleware } from '@nuxtjs/composition-api'

export default defineNuxtMiddleware((context) => {
  const { route, redirect } = context
  const { path } = route

  //\b — межа слова, щоб збіг відбувався на повному слові (наприклад, щоб уникнути збігів у середині іншого слова).
  // .*\.html та .*\.htm — дозволяє будь-які символи замість index, щоб збігатися з будь-яким текстом перед .html або .htm.
  // [\w\d*]* — будь-яка кількість букв, цифр або символ * після назви сторінки (може бути й порожньою).
  // \/?$ — необов'язковий слеш наприкінці, щоб збігатися зі сторінкою як зі слешем, так і без.
  const regex =  /\/([^\/]+\.html[^\/]*|[^\/]+\.htm[^\/]*)\/?$/;

  if (regex.test(path)) {
    console.log('htmlNotFoundRedirect', path)
    context.error({ statusCode: 404 })
    // Todo: redirect 404 page need to create
  }
})
