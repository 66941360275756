
import { defineComponent } from '@nuxtjs/composition-api'
import { plainToClass } from 'class-transformer'

import { MenuResponse, useApiGetMenu } from '~/utils/api/menu'
import { CategoryEntity } from '~/utils/models/category.entity'
import { MenuItemEntity } from '~/utils/models/menu-item'

export default defineComponent({
  data: () => ({
    selectedItem: null,
  }),

  computed: {
    menu(): MenuResponse {
      return this.$accessor.catalogMenu.menu
    },
    activeItemLink(): string {
      return this.activeItem.getLink(this.localePath)
    },
    activeItem(): MenuItemEntity {
      const item = this.items.find((item: any) => item.id === this.selectedItem)
      return item
    },
    selectedPopular(): CategoryEntity[] {
      const categoryIds = this.activeItem.data.favorite_category || []
      return categoryIds.map((id) => this.menu.favorite_category[id])
    },
    selectedItems(): any[] {
      return this.activeItem?.children || []
    },
    items(): any[] {
      const images = this.menu?.url || {}
      const items = this.menu?.data?.menuItems || []
      return items.map((item) => {
        const imageId = item.data.image as string
        const imageUrl = images[imageId]
        const newItem = {
          ...item,
          imageUrl,
        }
        return plainToClass(MenuItemEntity, newItem)
      })
    },
  },
  watch: {
    '$i18n.locale': function () {
      this.$accessor.catalogMenu.fetchItems()
    },
  },
  methods: {},
})
