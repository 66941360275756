import { Type } from 'class-transformer'
import { AttributeValueEntity } from './attribute-value.entity'
import { CategoryEntity } from './category.entity'
import { ILocaleValue } from './locale-value.entity'

export class TagEntity {
	id: number
	name: ILocaleValue
	slug: string
	media: any[]
	seo_title: ILocaleValue
	seo_description: ILocaleValue
	seo_keyphrases: ILocaleValue
	created_at: string
	groupped_attributes: { [key: string]: string[] }

	@Type(() => CategoryEntity)
	category?: CategoryEntity[]

	@Type(() => AttributeValueEntity)
	attribute_value: AttributeValueEntity[]
}
