import { defineNuxtPlugin } from '@nuxtjs/composition-api'

/*
  Todo: refactor to use i18n because lazy loading not working and
    inject full translation object by each request 25-50ms
 */
export default defineNuxtPlugin(({ i18n }, inject) => {
  inject('getLocaleValue', (obj: any) => {
    if (!obj) return ''
    const currentLocale = i18n.locale
    const defaultLocale = i18n.defaultLocale
    return obj[currentLocale] || obj[defaultLocale]
  })
})
