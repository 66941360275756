export const beautyPrice = (price: number, loading?: boolean): any => {
	let bPrice = new Intl.NumberFormat('ua').format(price)
	if (loading) {
		if (isNaN(price) || price === undefined) {
			return '...'
		} else {
			return new Intl.NumberFormat('ua').format(price)
		}
	}
	return bPrice
}
