import { PetsEntity } from './pets.entity'
import {Type} from "class-transformer";

export class UserEntity {
	id: number
	first_name: string
	last_name: string
	email: string
	phone: string
	gender: string
	birth_day: string

	@Type(() => PetsEntity)
	home_pet: PetsEntity[]
	// profile_photo_url: string

	get fullName() {
		return this.first_name && this.last_name ? this.first_name + ' ' + this.last_name : ''
	}
}
