import { Type } from 'class-transformer'
import useApi, { UseApiOptions } from '../compositions/useApi'
import { CategoryEntity } from '../models/category.entity'
import { MenuItemEntity } from '../models/menu-item'
import { MenuEntity } from '../models/menu.entity'
export class MenuResponse {
	@Type(() => MenuEntity)
	data: MenuEntity

	url: {
		[key: string]: string
	}

	favorite_category: {
		[key: string]: CategoryEntity
	}
}
export const useApiGetMenu = (opts?: UseApiOptions) => {
  return useApi<{ slug: string; locale: string }, MenuResponse>(
    ({ slug, locale }) => ({
      method: 'POST',
      url: '/menu/slug',
      data: {
        menuSlug: slug,
        localeCode: locale,
      },
    }),
    opts,
  )
}
