import { ref } from '@nuxtjs/composition-api'
import { LanguageCookie, LanguageModeCookieName } from '~/enums/languageCookie'
import { Context } from '@nuxt/types'

export const useLocaleMiddleware = (context: any) => {
  const { req, res, i18n } = context
  const langCountryHeader = req?.headers['x-country'] || null
  const userAgent = req?.headers['user-agent'] || ''
  const cookies = context.$cookies

  const botPatterns = [
    /google|Googlebot|Frog/i,
    /bing|yandex|msnbot/i,
    /DotBot|SemrushBot|YandexBot/i,
    /AltaVista|Slurp|BlackWidow|Bot|ChinaClaw|Custo|DISCo|Download|Demon|eCatch|EirGrabber|EmailSiphon|EmailWolf|SuperHTTP|Surfbot|WebWhacker/i,
    /Express|WebPictures|ExtractorPro|EyeNetIE|FlashGet|GetRight|GetWeb!|Go!Zilla|Go-Ahead-Got-It|GrabNet|Grafula|HMView/i,
    /rafula|HMView|HTTrack|Stripper|Sucker|Indy|InterGET|Ninja|JetCar|Spider|larbin|LeechFTP|Downloader|tool|Navroad|NearSite|NetAnts|tAkeOut|WWWOFFLE/i,
    /GrabNet|NetSpider|Vampire|NetZIP|Octopus|Offline|PageGrabber|Foto|pavuk|pcBrowser|RealDownload|ReGet|SiteSnagger|SmartDownload|SuperBot|WebSpider/i,
    /Teleport|VoidEYE|Collector|WebAuto|WebCopier|WebFetch|WebGo|WebLeacher|WebReaper|WebSauger|eXtractor|Quester|WebStripper|WebZIP|Wget|Widow|Zeus/i,
    /Twengabot|htmlparser|libwww|Python|perl|urllib|scan|Curl|email|PycURL|Pyth|PyQ|WebCollector|WebCopy|webcraw|MegaIndex/i,
    /GeedoProductSearch/i,
  ]

  const isBot = botPatterns.some((pattern) => pattern.test(userAgent))
  const isHasHeader = langCountryHeader !== null && langCountryHeader === 'ua'
  const manualCookie = cookies.get(LanguageModeCookieName)
  const isHasLocaleManualCookie = manualCookie?.length > 0

  // console.log(isBot, isHasLocaleManualCookie)
  if (!isBot && !isHasLocaleManualCookie) {
    if (i18n.getLocaleCookie() != 'ua') i18n.setLocale('ua')
  } else if (!isBot && isHasLocaleManualCookie) {
    if (i18n.getLocaleCookie() != manualCookie ) i18n.setLocale(manualCookie)
  }

  return {
    isBot,
    isHasHeader,
    isHasLocaleManualCookie,
  }
}
