import { WithContext, ListItem } from 'schema-dts'

export function createFromBreadcrumbsListItemEntityToJsonLd(breadcrumbsListItem: any): WithContext<ListItem> {
  return breadcrumbsListItem.filter((item: { name: any; }) => item.name !== undefined && item.name.length > 0).map((bItem: any, index: number) => ({
    '@context': 'https://schema.org',
    '@type': 'ListItem',
    position: index + 1,
    item: {
      '@id': bItem.link,
      name: bItem.name
    }
  }));
}
