import { getterTree, mutationTree, actionTree } from 'typed-vuex'
import cloneDeep from "lodash.clonedeep"
import { plainToClass } from "class-transformer"
import { useApiGetProducts } from '~/utils/api/product'
import useCookie from '~/utils/compositions/useCookie'
import config from '~/utils/config/config'
import { ProductEntity } from "~/utils/models/product.entity"
import { useApiAddFavourite, useApiDeleteFavourite, useApiGetFavourite } from "~/utils/api/favourite"
import { useApiAddWatched, useApiGetWatched, useApiClearWatched } from "~/utils/api/watched"
const COOKIE_KEY = 'watched-products'
const COOKIE_USER_SESSION = 'user-session'

interface WatchedItem {
	id: number
	productId: number
}
export const state = () => ({
	items: [] as WatchedItem[],
	products: [] as ProductEntity[],
	isFetched: false,
})
export const getters = getterTree(state, {
	iWatched(state) {
		return (id: number) => {
			return state.items.findIndex((item) => item.id === id) > -1
		}
	},
	productModels(state): ProductEntity[] {
		return state.products.map((product, index) => {
			const productModel = plainToClass(ProductEntity, product)
			const WatchedItem = state.items[index]
			const activeItemId = WatchedItem?.id
			if (activeItemId) {
				productModel.setActiveVariation(activeItemId)
			}
			return productModel
		})
	},
})

export const mutations = mutationTree(state, {
	setItems(state, items) {
		state.items = items
	},
	setProducts(state, products) {
		state.products = products
	},
	setFetched(state, value) {
		state.isFetched = value
	},
})

export const actions = actionTree(
	{ state, getters, mutations },
	{
		async syncOnLogin({ state, commit }) {
			const cookieItemsIds = this.$cookies.get(COOKIE_KEY)
			if (cookieItemsIds && cookieItemsIds.length > 0) {
				const itemsIds = cookieItemsIds.map((item:WatchedItem) => item.id)
				await this.app.$accessor.watched.addWatched(itemsIds)
			}
			await this.app.$accessor.watched.getWatched()
		},

		// request to getWatched
		async getWatched({ state, commit }) {
			const userSession = this.$cookies.get(COOKIE_USER_SESSION)
			const { error, exec, result } = useApiGetWatched()
			await exec({
				user_id: userSession.user_id,
				session_id: userSession.session_id,
			})
			if (error.value) {
				console.error(error.value)
				return
			}
			const response = result.value
			if (response.data.length > 0 && response.total > 0) {
				await this.app.$accessor.watched.resetItems()
				const responseWatched = await response.data.map((item: any) => {
					return { id: item.product_item_id, productId: item.product_id }
				})
				this.$cookies.set(COOKIE_KEY, responseWatched)
				commit('setItems', responseWatched)
				commit('setFetched', false)
			}
			return response
		},

		async resetItems({ state, commit }) {
			this.$cookies.remove(COOKIE_KEY)
			commit('setItems', [])
			commit('setFetched', false)
		},

		// request to removeWatched
		async removeWatched({ state, commit }) {
			const userSession = this.$cookies.get(COOKIE_USER_SESSION)
			const { error, exec, result } = useApiClearWatched()
			await exec({
				user_id: userSession.user_id,
				session_id: userSession.session_id,
			})
			if (error.value) {
				console.error(error.value)
				return
			}
			return result
		},

		async addItem({ state, commit }, { id, productId }) {
			if (this.app.$accessor.watched.iWatched(id)) return
			const newItems = [{ id, productId }, ...state.items]
			if (newItems.length > Number(process.env.PRODUCTS_MAX_WATCHED)) {
				newItems.pop()
			}
			this.$cookies.set(COOKIE_KEY, newItems)
			commit('setItems', newItems)
			commit('setFetched', false)
			// isAuth addWatched
			if (this.app.$accessor.user.isAuth) {
				await this.app.$accessor.watched.addWatched([id])
			}
		},

		// request to addWatched
		async addWatched({ state, commit }, ids: number[]) {
			const userSession = this.$cookies.get(COOKIE_USER_SESSION)
			const { error, exec, result } = useApiAddWatched()
			await exec({
				user_id: userSession.user_id,
				session_id: userSession.session_id,
				product_item_id: ids
			})
			if (error.value) {
				console.error(error.value)
				return
			}
			return result
		},

		init({ commit }) {
			const itemsIds = this.$cookies.get(COOKIE_KEY)
			if (itemsIds && Array.isArray(itemsIds)) {
				commit('setItems', itemsIds)
			} else {
				this.$cookies.remove(COOKIE_KEY)
			}
		},

		async fetchProducts({ state, commit }) {
			if (state.isFetched) return
			const { exec, result } = useApiGetProducts()
			await exec({ ids: state.items.map((item) => item.productId) })
			const products = result.value
			const newProducts = state.items
				.map((item, index) => {
					let product = products?.find(
						(prod) => prod.id === item.productId
					)
					if (
						state.items.findIndex((itm) => itm.id === item.id) !==
						index
					) {
						product = cloneDeep(product)
					}
					return product as ProductEntity
				})
				.filter((item) => !!item)
			const newItems = state.items.filter(
				(item) =>
					newProducts.findIndex(
						(prod) => prod.id === item.productId
					) > -1
			)
			commit('setItems', newItems)
			commit('setProducts', newProducts)
			commit('setFetched', true)
		},
	}
)
