import 'reflect-metadata'

import { defineNuxtMiddleware } from '@nuxtjs/composition-api'
import friendlyUrlCheckRedirect from '~/utils/helpers/friendlyUrlCheckRedirect'

// step 1: parse url
// step 2: check if url is semantic in the database / cache / etc.
// step 3: if exists, redirect to the semantic url with 301 status code
// Todo: move to util helpers
export default defineNuxtMiddleware(async (context) => {
  await friendlyUrlCheckRedirect(context)
})
