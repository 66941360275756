
























import { defineComponent, useContext, useRoute } from '@nuxtjs/composition-api'

import useCatalogModal from '~/utils/compositions/useCatalogModal'
import { useLocaleMiddleware } from '~/utils/compositions/useLocaleMiddleware'

// Todo: refactor axios get groups and set to store with cache
export default defineComponent({
  setup() {
    const { isOpen: showCatalogModal } = useCatalogModal()
    const route = useRoute()
    const context = useContext()
    useLocaleMiddleware(context)

    return {
      showCatalogModal,
      route,
    }
  },
  head() {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true })
    i18nHead.htmlAttrs = { lang: this.$i18n.locale }

    // remove canonical on home page
    const notCanonical = ['/ua/', '/']
    if (notCanonical.includes(this.route.fullPath)) {
      i18nHead.link = i18nHead.link.filter((link) => link.rel !== 'canonical')
    }

    // skip hreflang: 'uk' | hreflang: 'ru'
    const notDefaultAlt = ['uk', 'ru', 'x-default']
    i18nHead.link = i18nHead.link.filter((link) => {
      if (link.rel === 'alternate') {
        if (notDefaultAlt.includes(link.hreflang)) {
          return false
        }
      }
      return true
    })

    // **Remove filters if they exist in URLs**
    // **Function to remove '/f/' and everything after**
    function removeFilterFromURL(url: string) {
      let newUrl = url
      // Remove query parameters
      // eslint-disable-next-line prefer-destructuring
      newUrl = newUrl.split('?')[0]

      // Remove '/f/' and everything after it
      newUrl = newUrl.replace(/\/f\/.*$/, '')

      // Ensure URL ends with a single trailing slash
      if (!newUrl.endsWith('/')) {
        newUrl += '/'
      }

      return newUrl
    }

    // Update canonical and alternate links to exclude filters
    i18nHead.link = i18nHead.link.map((link) => {
      if (['canonical', 'alternate'].includes(link.rel)) {
        // eslint-disable-next-line no-param-reassign
        link.href = removeFilterFromURL(link.href)
      }
      return link
    })

    return { ...i18nHead }
  },
})
