import { getterTree, mutationTree, actionTree } from 'typed-vuex'

import { MenuResponse, useApiGetMenu } from '~/utils/api/menu'
import { Context } from '@nuxt/types'

export const state = () => ({
	menu: {} as MenuResponse,
	isFetching: false,
})
export const mutations = mutationTree(state, {
	setMenu(state, menu) {
		state.menu = menu
	},
	setFetching(state, value) {
		state.isFetching = value
	},
})
export const getters = getterTree(state, {})

export const actions = actionTree(
	{ state, getters, mutations },
	{
		async fetchItems({ commit, state }) {
      const data = false //this.app.$cache.data.get(`menu_catalog_${this.$i18n.locale}`)
      let existCache = false
      if (data) {
        await Promise.resolve(data).then((res) => {
          if (typeof res !== undefined && res !== null && typeof res !== 'undefined') {
            commit('setMenu', res)
            existCache = true
            return
          }
        })
      }

      if (existCache) return

			if (state.isFetching) return
			commit('setFetching', true)
			const { exec, result, error } = useApiGetMenu()
			await exec({ locale: this.$i18n.locale, slug: 'catalog' })

      commit('setMenu', result.value)
      // this.app.$cache.data.set(`menu_catalog_${this.$i18n.locale}`, result.value)
      commit('setFetching', false)
    },
		async init({ commit }) {
			await this.app.$accessor.catalogMenu.fetchItems()
    },
  },
)
