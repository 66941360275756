import { defineNuxtPlugin } from '@nuxtjs/composition-api'

export default defineNuxtPlugin((ctx, inject) => {
  const meta = [
    {
      name: 'facebook-domain-verification',
      content: 'vf1chfp9ua0zca862g2kas7twtp7tk',
    },
    {
      name: 'google-site-verification',
      content: 'fPjvr7TmPS1vEyZskRpPtU9wEhlQ05__e880bPeh0ws',
    },
  ]

  if (typeof ctx.app.head === 'function') {
    console.error("We can't add the meta if head is a function")
  } else if (ctx.app.head) {
    ctx.app.head.meta = [...(ctx.app.head.meta || []), ...meta]
  } else {
    ctx.app.head = {
      meta,
    }
  }
})
