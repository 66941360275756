import { defineNuxtMiddleware } from '@nuxtjs/composition-api'

export default defineNuxtMiddleware((context) => {
  const { route, redirect } = context
  const { path } = route

  // Завжди робимо редірект на нижній регістр
  const lowerCasePath = path.toLowerCase()

  // Якщо шлях змінюється, робимо редірект
  if (path !== lowerCasePath) {
    redirect(301, lowerCasePath)
  }
})
