import { getterTree, mutationTree, actionTree } from 'typed-vuex'

export const state = () => ({})
export const getters = getterTree(state, {
    symbol() {
        return 'грн'
    },
    isoCode() {
        return 'UAH'
    }
})
