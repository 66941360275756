import { render, staticRenderFns } from "./default.vue?vue&type=template&id=106aeb6c&"
import script from "./default.vue?vue&type=script&lang=ts&"
export * from "./default.vue?vue&type=script&lang=ts&"
import style0 from "./default.vue?vue&type=style&index=0&lang=postcss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/var/www/html/components/Header/Header.vue').default,MenuMob: require('/var/www/html/components/MenuMob/MenuMob.vue').default,Footer: require('/var/www/html/components/Footer/Footer.vue').default,Modal: require('/var/www/html/components/Modal/Modal.vue').default})
