import { Type } from 'class-transformer'
import { MenuItemEntity } from './menu-item'

export class MenuEntity {
	id: number
	name: string
	slug: string
	locale: string
	@Type(() => MenuItemEntity)
	menuItems: MenuItemEntity[]
}
