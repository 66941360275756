import { Type } from 'class-transformer'
import { AxiosResponse } from 'axios'
import useApi, { UseApiOptions } from '../compositions/useApi'
import { ProductEntity } from '../models/product.entity'
import { CategoryWholeEntity } from '../models/category-whole.entity'
import { BrandEntity } from '../models/brand.entity'
import dataTransformer from '~/utils/transformers/data-transformer'
import { SeoFilter } from '~/utils/models/seo-filter.entity'

class GetCategoryResponseData {
	@Type(() => ProductEntity)
	product: ProductEntity[]
}
class GetCategoryResponse {
	@Type(() => GetCategoryResponseData)
	data: GetCategoryResponseData
}
export const useApiGetCategory = (opts?: UseApiOptions) =>
	useApi<{ slug: string }, CategoryWholeEntity>(
		({ slug }) => ({
			method: 'POST',
			url: '/category/whole',
			data: {
				slug,
			},
		}),
		opts,

		({ data }: AxiosResponse<{ data: CategoryWholeEntity }>) => data.data
	)
export const useApiGetCategoryFilters = (opts?: UseApiOptions) =>
	useApi<any, CategoryWholeEntity>(
		() => ({
			method: 'GET',
			url: '/product/slug-value',
			data: {},
		}),
		opts,
		({ data }: AxiosResponse<{ data: CategoryWholeEntity }>) => data.data
	)
export const useApiGetCategoryBrands = (opts?: UseApiOptions) =>
  useApi<{ id: number }, BrandEntity[]>(
		({ id }) => ({
			method: 'POST',
			url: '/category/brands',
			data: { id },
		}),
    opts,
    dataTransformer,
	)

export const useApiGetCategorySeoFilters = (opts?: UseApiOptions) =>
  useApi<{ categoryId: number; brandId: number; attributeId: number[] }, SeoFilter>(
    ({ categoryId, brandId, attributeId }) => ({
      method: 'POST',
      url: '/get-seo-filter',
      data: {
        categoryId,
        brandId,
        attributeId,
      },
    }),
    opts,
    (data) => data.data,
  )

export const useApiCheckCategorySeoFiltersBySlug = (opts?: UseApiOptions) =>
  useApi<{ categorySlug: string; friendlySlug: string }, SeoFilter>(
    ({ categorySlug, friendlySlug }) => ({
      method: 'POST',
      url: '/check-seo-filter-by-slug',
      data: {
        slug: friendlySlug,
        category_slug: categorySlug,
      },
    }),
    opts,
    (data) => data.data,
  )

export const useApiGetActiveFiltersBySeoFilterSlug = (opts?: UseApiOptions) =>
  useApi<{ categorySlug: string; friendlySlug: string }, SeoFilter>(
    ({ categorySlug, friendlySlug }) => ({
      method: 'POST',
      url: '/get-active-filters-by-slug',
      data: {
        slug: friendlySlug,
        category_slug: categorySlug,
      },
    }),
    opts,
    (data) => data.data,
  )


export const useApiCheckCategorySeoFiltersByMultiple = (opts?: UseApiOptions) =>
  useApi<
    {
      categorySlug: string
      brandSlug: string | null
      attributeSlug: string | null
      attributeSlugValues: Array<string> | null
    },
    SeoFilter
  >(
    ({ categorySlug, brandSlug, attributeSlug, attributeSlugValues }) => ({
      method: 'POST',
      url: '/check-seo-filter-by-multiple',
      data: {
        category_slug: categorySlug,
        brand_slug: brandSlug,
        attribute_slug: attributeSlug,
        attribute_slug_values: attributeSlugValues,
      },
    }),
    opts,
    (data) => data.data,
  )
