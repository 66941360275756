import { plainToClass } from 'class-transformer'
import { getterTree, mutationTree, actionTree } from 'typed-vuex'
import cloneDeep from "lodash.clonedeep"
import { useApiGetProducts } from '~/utils/api/product'
import config from '~/utils/config/config'
import { ProductEntity } from '~/utils/models/product.entity'
import { useApiAddFavourite, useApiGetFavourite, useApiDeleteFavourite } from "~/utils/api/favourite"
import { useApiSendLogin } from "~/utils/api/auth"

const COOKIE_KEY = 'favourite-products'
const COOKIE_USER_SESSION = 'user-session'

interface FavouriteItem {
	id: number
	productId: number
}
export const state = () => ({
	items: [] as FavouriteItem[],
	products: [] as ProductEntity[],
	isFetched: false,
})
export const getters = getterTree(state, {
	isFavourite(state) {
		return (id: number) => {
			return state.items.findIndex((item) => item.id === id) > -1
		}
	},
	productModels(state): ProductEntity[] {
		return state.products.map((product, index) => {
			const productModel = plainToClass(ProductEntity, product)
			const favouriteItem = state.items[index]
			const activeItemId = favouriteItem?.id
			if (activeItemId) {
				productModel.setActiveVariation(activeItemId)
			}
			return productModel

		})
	},
})

export const mutations = mutationTree(state, {
	setItems(state, items) {
		state.items = items
	},
	setProducts(state, products) {
		state.products = products
	},
	setFetched(state, value) {
		state.isFetched = value
	},
})

export const actions = actionTree(
	{ state, getters, mutations },
	{
		async syncOnLogin({ state, commit }) {
			const cookieItemsIds = this.$cookies.get(COOKIE_KEY)
			if (cookieItemsIds && cookieItemsIds.length > 0) {
				const itemsIds = cookieItemsIds.map((item:FavouriteItem) => item.id)
				await this.app.$accessor.favourite.addFavourite(itemsIds)
			}
			await this.app.$accessor.favourite.getFavourite()
		},

		// request to getFavourite
		async getFavourite({ state, commit }) {
			const userSession = this.$cookies.get(COOKIE_USER_SESSION)
			const { error, exec, result } = useApiGetFavourite()
			await exec({
				user_id: userSession.user_id,
				session_id: userSession.session_id,
			})
			if (error.value) {
				console.error(error.value)
				return
			}
			const response = result.value
			if (response.data.length > 0 && response.total > 0) {
				await this.app.$accessor.favourite.resetItems(undefined)
				const responseFavourites = await response.data.map((item: any) => {
					return { id: item.product_item_id, productId: item.product_id }
				})
				this.$cookies.set(COOKIE_KEY, responseFavourites)
				commit('setItems', responseFavourites)
				commit('setFetched', false)
			}
			return response
		},

		async resetItems({ state, commit }, ids: number[] | undefined) {
			this.$cookies.remove(COOKIE_KEY)
			commit('setItems', [])
			commit('setFetched', false)
			// isAuth removeFavourite
			if (this.app.$accessor.user.isAuth && ids && ids.length > 0) {
				await this.app.$accessor.favourite.removeFavourite(ids)
			}
		},

		async removeItem(
			{ state, getters, commit },
			{
				id,
				removeRequest = true
			}: any & { removeRequest?: Boolean}) {
			if (!this.app.$accessor.favourite.isFavourite(id)) return
			if (state.items.findIndex((item) => item.id === id) < 0) return
			const newItems = state.items.filter((item) => item.id !== id)
			this.$cookies.set(COOKIE_KEY, newItems)
			commit('setItems', newItems)
			commit('setFetched', false)
			// await this.app.$accessor.favourite.fetchProducts()
			// isAuth removeFavourite
			if (this.app.$accessor.user.isAuth && removeRequest) {
				await this.app.$accessor.favourite.removeFavourite([id])
			}
		},

		// request to removeFavourite
		async removeFavourite({ state, commit }, ids: number[]) {
			const userSession = this.$cookies.get(COOKIE_USER_SESSION)
			const { error, exec, result } = useApiDeleteFavourite()
			await exec({
				user_id: userSession.user_id,
				session_id: userSession.session_id,
				product_item_id: ids
			})
			if (error.value) {
				console.error(error.value)
				return
			}
			return result
		},

		async addItem({ state, commit }, { id, productId }: FavouriteItem) {
			if (this.app.$accessor.favourite.isFavourite(id)) return
			const newItems = [{ id, productId }, ...state.items]
			if (newItems.length > Number(process.env.PRODUCTS_MAX_FAVOURITE)) {
				newItems.pop()
			}
			this.$cookies.set(COOKIE_KEY, newItems)
			commit('setItems', newItems)
			commit('setFetched', false)
			// await this.app.$accessor.favourite.fetchProducts()
			// isAuth addFavourite
			if (this.app.$accessor.user.isAuth) {
				await this.app.$accessor.favourite.addFavourite([id])
			}
		},

		// request to addFavourite
		async addFavourite({ state, commit }, ids: number[]) {
			const userSession = this.$cookies.get(COOKIE_USER_SESSION)
			const { error, exec, result } = useApiAddFavourite()
			await exec({
				user_id: userSession.user_id,
				session_id: userSession.session_id,
				product_item_id: ids
			})
			if (error.value) {
				console.error(error.value)
				return
			}
			return result
		},

		init({ commit }) {
			const itemsIds = this.$cookies.get(COOKIE_KEY)
			if (itemsIds && Array.isArray(itemsIds)) {
				commit('setItems', itemsIds)
			} else {
				this.$cookies.remove(COOKIE_KEY)
			}
		},

		async fetchProducts({ state, commit }) {
			if (state.isFetched) return
			const { exec, result } = useApiGetProducts()
			await exec({ ids: state.items.map((item) => item.productId) })
			const products = result.value
			const newProducts = state.items
				.map((item, index) => {
					let product = products?.find(
						(prod) => prod.id === item.productId
					)
					if (
						state.items.findIndex((itm) => itm.id === item.id) !==
						index
					) {
						product = cloneDeep(product)
					}
					return product as ProductEntity
				})
				.filter((item) => !!item)
			const newItems = state.items.filter(
				(item) =>
					newProducts.findIndex(
						(prod) => prod.id === item.productId
					) > -1
			)
			commit('setItems', newItems)
			commit('setProducts', newProducts)
			commit('setFetched', true)
		},
	}
)
