import {plainToClass} from 'class-transformer'
import {getterTree, mutationTree, actionTree} from 'typed-vuex'
import cloneDeep from 'lodash.clonedeep'
import {useContext} from '@nuxtjs/composition-api'
import {useApiGetProductItems, useApiGetProducts} from '~/utils/api/product'
import config from '~/utils/config/config'
import {ProductEntity, ProductItem, GtagProductEntity} from '~/utils/models/product.entity'
import useModal from '~/utils/compositions/useModal'
import {ModalName} from '~/types/modal.enum'
import {
  useApiAddCart,
  useApiDeleteCart,
  useApiGetCart,
  useApiIncrCart,
  useApiDecrCart
} from "~/utils/api/cart"
import transformProductToGtag from '~/components/ProductCard/product-card-gtag'
import {beautyPrice} from "~/utils/helpers/beauty-price"

const COOKIE_KEY = 'cart-products'
const COOKIE_USER_SESSION = 'user-session'

const getAmount = (state: any, getters: any, isGtagAmount = false) =>
  getters.productModels.reduce((sum: number, item: ProductEntity) => {
    const price = isGtagAmount ? item.oldPrice || item.price : item.price
    const cnt = getters.itemCnt(item.activeItem.id)
    // eslint-disable-next-line no-param-reassign
    sum += price * cnt
    return sum
  }, 0)

interface CartItem {
  cnt: number
  id: number
  productId: number
}

export const state = () => ({
  items: [] as CartItem[],
  products: [] as ProductEntity[],
  isFetched: false,
})
export const getters = getterTree(state, {
  positionInCart(state) {
    return (id: number) => {
      const index = state.items.findIndex((item) => item.id === id)
      return index > -1 ? index + 1 : 0
    }
  },
  inCart(state) {
    return (id: number) => {
      return state.items.findIndex((item) => item.id === id) > -1
    }
  },
  itemCnt(state) {
    return (id: number) => {
      return state.items.find((item) => item.id === id)?.cnt
    }
  },
  totalCnt(state) {
    return state.products.length
  },
  productModels(state): ProductEntity[] {
    return state.products.map((product, index) => {
      const productModel = plainToClass(ProductEntity, product)
      const cartItem = state.items[index]
      const activeItemId = cartItem?.id
      if (activeItemId) {
        productModel.setActiveVariation(activeItemId)
      }
      return productModel
    })
  },
  // eslint-disable-next-line no-shadow
  gtagProducts(state, getters) {
    return (getLocalValue: Function) =>
      state.products.map((product, index) => {
        const productModel = plainToClass(ProductEntity, product)
        const cartItem = state.items[index]
        const activeItemId = cartItem?.id
        if (activeItemId) {
          productModel.setActiveVariation(activeItemId)
        }
        const activeItemID = activeItemId || product.id
        const itemCnt = getters.itemCnt(activeItemID) || 1
        const itemPos = getters.positionInCart(activeItemID) || index

        return transformProductToGtag(productModel, getLocalValue, itemCnt, itemPos)
      })
  },
  // eslint-disable-next-line no-shadow
  totalAmount(state, getters) {
    const amount: number = getAmount(state, getters)
    return +amount.toFixed(2)
  },
  // eslint-disable-next-line no-shadow
  gtagTotalAmount(state, getters) {
    const amount: number = getAmount(state, getters, true)
    return +amount.toFixed(2)
  },
})

export const mutations = mutationTree(state, {
  // eslint-disable-next-line no-shadow
  setItems(state, items) {
    // eslint-disable-next-line no-param-reassign
    state.items = items
  },
  // eslint-disable-next-line no-shadow
  setProducts(state, products) {
    // eslint-disable-next-line no-param-reassign
    state.products = products
  },
  // eslint-disable-next-line no-shadow
  setFetched(state, value) {
    // eslint-disable-next-line no-shadow
    state.isFetched = value
  },
})

export const actions = actionTree(
  {state, getters, mutations},
  {
    async syncOnLogin({state, commit}) {
      const cookieItemsIds = this.$cookies.get(COOKIE_KEY)
      if (cookieItemsIds && cookieItemsIds.length > 0) {
        await this.app.$accessor.cart.addCart(cookieItemsIds)
      }
      await this.app.$accessor.cart.getCart()
    },
    // request to getCart
    async getCart({state, commit}) {
      const userSession = this.$cookies.get(COOKIE_USER_SESSION)
      const {error, exec, result} = useApiGetCart()
      await exec({
        user_id: userSession.user_id,
        session_id: userSession.session_id,
      })
      if (error.value) {
        console.error(error.value)
        return
      }
      const response = result.value
      if (response && response.length > 0) {
        await this.app.$accessor.cart.resetItems(undefined)
        const responseCart = await response.map((item: any) => {
          return {
            id: item.product_item_id,
            cnt: item.product_item_count,
            productId: item.product_id
          }
        })
        this.$cookies.set(COOKIE_KEY, responseCart)
        commit('setItems', responseCart)
        commit('setFetched', false)
        await this.app.$accessor.cart.fetchProducts()
      }
      return response
    },

    async resetItems({state, commit}, ids: number[] | undefined) {
      this.$cookies.remove(COOKIE_KEY)
      commit('setItems', [])
      commit('setProducts', [])
      // isAuth removeCart
      if (this.app.$accessor.user.isAuth && ids && ids.length > 0) {
        await this.app.$accessor.cart.removeCart(ids)
      }
    },

    async removeItem({state, commit}, id: number) {
      if (state.items.findIndex((item) => item.id === id) < 0) return
      const newItems = state.items.filter((item) => item.id !== id)
      this.$cookies.set(COOKIE_KEY, newItems)
      commit('setItems', newItems)
      // isAuth removeCart
      if (this.app.$accessor.user.isAuth) {
        await this.app.$accessor.cart.removeCart([id])
      }
      await this.app.$accessor.cart.fetchProducts()
    },

    // request to removeCart
    async removeCart({state, commit}, ids: number[]) {
      const userSession = this.$cookies.get(COOKIE_USER_SESSION)
      const {error, exec, result} = useApiDeleteCart()
      await exec({
        user_id: userSession.user_id,
        session_id: userSession.session_id,
        product_item_id: ids
      })
      if (error.value) {
        console.error(error.value)
        return
      }
      return result
    },

    // request to incrCart
    async incrCart({state, commit}, id: number) {
      const userSession = this.$cookies.get(COOKIE_USER_SESSION)
      const {error, exec, result} = useApiIncrCart()
      await exec({
        user_id: userSession.user_id,
        session_id: userSession.session_id,
        product_item_id: id
      })
      if (error.value) {
        console.error(error.value)
        return
      }
      return result
    },

    // request to decrCart
    async decrCart({state, commit}, id: number) {
      const userSession = this.$cookies.get(COOKIE_USER_SESSION)
      const {error, exec, result} = useApiIncrCart()
      await exec({
        user_id: userSession.user_id,
        session_id: userSession.session_id,
        product_item_id: id
      })
      if (error.value) {
        console.error(error.value)
        return
      }
      return result
    },

    async addItem(
      {state, commit},
      {
        id,
        cnt,
        productId,
        fetchItems = true,
        showModal = true,
      }: CartItem & { fetchItems?: boolean; showModal?: boolean }
    ) {
      if (state.items.findIndex((item) => item.id === id) > -1) {
        const itemCnt = this.app.$accessor.cart.itemCnt(id) || 0
        const newCnt = itemCnt + cnt
        this.app.$accessor.cart.changeCnt({id, cnt: newCnt})
      } else {
        const newItems = [{id, cnt, productId}, ...state.items]

        if (newItems.length > Number(process.env.PRODUCTS_MAX_IN_CART)) {
          newItems.pop()
        }

        this.$cookies.set(COOKIE_KEY, newItems)
        commit('setItems', newItems)
        // isAuth addCart
        if (this.app.$accessor.user.isAuth) {
          await this.app.$accessor.cart.addCart([{id, cnt}])
        }
        if (fetchItems) {
          await this.app.$accessor.cart.fetchProducts()
        }
      }
      const activeItemIdx = state.items.findIndex(
        (item) => item.id === id
      )

      const activeProduct = this.app.$accessor.cart.productModels[activeItemIdx]
      this.$gtm.push({
        event: 'add_to_cart',
        ecommerce: {
          currency: this.app.$accessor.currency.isoCode,
          items: [
            {
              item_id: activeProduct?.id,
              item_name: this.$getLocaleValue(activeProduct?.activeItem.title),
              quantity: cnt,
              index: activeItemIdx,
              price: beautyPrice(activeProduct.price as number),
            },
          ],
        },
      })

      if (showModal) {
        const {showByName} = useModal()
        showByName(ModalName.addedToCart, {
          props: {
            image: activeProduct?.activeItem.defaultImage,
            name: this.$getLocaleValue(activeProduct?.activeItem.title),
          },
        })
      }
      return undefined
    },

    // request to addCart
    async addCart({state, commit}, items: any[]) {
      const userSession = this.$cookies.get(COOKIE_USER_SESSION)
      const productItemScope = items.map((item) => {
        return {
          product_item_id: item.id,
          product_item_count: item.cnt
        }
      })
      const {error, exec, result} = useApiAddCart()
      await exec({
        user_id: userSession.user_id,
        session_id: userSession.session_id,
        product_item_scope: productItemScope
      })
      if (error.value) {
        console.error(error.value)
        return
      }
      return result
    },

    changeCnt({state, commit}, {id, cnt}: { id: number; cnt: number }) {
      const itemIdx = state.items.findIndex((item) => item.id === id)
      if (itemIdx < 0) return
      const newItems = [...state.items]
      const newItem = {
        ...newItems[itemIdx],
        cnt,
      }
      newItems[itemIdx] = newItem
      this.$cookies.set(COOKIE_KEY, newItems)
      commit('setItems', newItems)
    },

    async init({commit}) {
      const itemsIds = this.$cookies.get(COOKIE_KEY)
      if (itemsIds && Array.isArray(itemsIds)) {
        commit('setItems', itemsIds)
        await this.app.$accessor.cart.fetchProducts()
      } else {
        this.$cookies.remove(COOKIE_KEY)
      }
    },
    async fetchProducts({state, commit}) {
      const {exec, result, error} = useApiGetProducts()
      await exec({ids: state.items.map((item) => item.productId)})
      if (error.value) return
      const products = result.value

      const newProducts = state.items
        .map((item, index) => {
          let product = products?.find(
            (prod) => prod.id === item.productId
          )
          if (
            state.items.findIndex((itm) => itm.id === item.id) !==
            index
          ) {
            product = cloneDeep(product)
          }
          return product as ProductEntity
        })
        .filter((item) => !!item)
      const newItems = state.items.filter(
        (item) =>
          newProducts.findIndex(
            (prod) => prod.id === item.productId
          ) > -1
      )
      commit('setItems', newItems)
      commit('setProducts', newProducts)
      commit('setFetched', true)
    },
  }
)
