import { ref, watch } from '@nuxtjs/composition-api'
import { uid } from 'uid'
const queue = ref<any[]>([] as any)
export const isLoading = ref(false)

export function useLoading() {
	const id = uid()
	const isLoading = ref(false)
	const start = () => {
		isLoading.value = true
		queue.value.push({ id })
	}
	const stop = () => {
		isLoading.value = false
		queue.value = queue.value.filter((item: any) => item.id !== id)
	}
	return { start, stop, isLoading }
}

let timeout: any
watch(queue, () => {
	if (!queue.value.length) {
		timeout = setTimeout(() => {
			isLoading.value = false
		}, 100)
	} else {
		if (timeout) {
			clearTimeout(timeout)
		}
		isLoading.value = true
	}
})
